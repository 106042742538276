import Component from './App.js'
import { hydrate } from 'react-dom'

const elements = document.querySelectorAll('*[data-componentid="domain_App_js"]')
for (let i = 0; i < elements.length; i++) {
  const props = JSON.parse(elements[i].dataset.props)
  hydrate(<Component {...props} />, elements[i])
}

if (module.hot) {
  require('@kaliber/build/lib/hot-module-replacement-client')
  module.hot.accept('./App.js', () => {
    for (let i = 0; i < elements.length; i++) {
      const props = JSON.parse(elements[i].dataset.props)
      hydrate(<Component {...props} />, elements[i])
    }
  })
}
